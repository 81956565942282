.customerPage {
  height: 100%;
  width: 100%;
  background: linear-gradient(rgba(35, 43, 85, 0.75), rgba(35, 43, 85, 0.95)),
    no-repeat center center;
  box-shadow: 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  overflow: auto;
}
