.mySelect {
  position: relative;
}
.select {
  color: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: 35px;
  padding-left: 15px;
  border: none;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.2);
  margin-bottom: 20px;
  box-shadow: 0.1em 0.1em 0.5em rgba(0, 0, 0, 0.3);
  font-family: inherit;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
