.checkboxLabel {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 13px;
  padding-left: 15px;
  padding-bottom: 10px;
  color: rgba(255, 255, 255, 0.7);
}
.checkBoxWrapper {
  display: flex;
  align-items: flex-start;
}
