.bigButton {
  width: 100%;
  height: 35px;
  color: white;
  border: none;
  border-radius: 20px;
  background: lightsalmon;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 13px;
  cursor: pointer;
  font-family: inherit;
  box-shadow: 0.1em 0.1em 0.5em rgba(0, 0, 0, 0.3);
}

.backBigButton {
  width: 100%;
  height: 35px;
  color: white;
  border: none;
  border-radius: 20px;
  background: rgba(220, 20, 60, 0.8);
  font-weight: 400;
  text-transform: uppercase;
  font-size: 13px;
  cursor: pointer;
  font-family: inherit;
  box-shadow: 0.1em 0.1em 0.5em rgba(0, 0, 0, 0.3);
}

.bigButton:active {
  background: rgb(124, 204, 124);
}
.bigButton:disabled {
  background: rgba(124, 204, 124, 0.9);
}
.backBigButton:active {
  background: rgb(214, 77, 105);
}
