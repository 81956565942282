.formInput {
  color: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: 35px;
  padding-left: 15px;
  border: none;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.2);
  margin-bottom: 20px;
  box-shadow: 0.1em 0.1em 0.5em rgba(0, 0, 0, 0.3);
  font-family: inherit;
}
.formInput::placeholder {
  color: black;
  opacity: 0.5;
}
.formLabel {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 13px;
  padding-left: 15px;
  padding-bottom: 10px;
  color: rgba(255, 255, 255, 0.7);
}
.priceLabel {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
}
.fieldWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}
.formWrapper {
  width: 560px;
  height: 100%;
}
.formErrorField {
  box-shadow: 0px 0px 0px 1.5px #ff0000;
}
.formRequiredField {
  position: absolute;
  bottom: -2px;
  right: 15px;
  color: #ff0000;
}
.loginForm {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
