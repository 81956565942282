.userPage {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 20px;
  margin: 20px 0px;
  width: 600px;
  border-radius: 5px;
  background: linear-gradient(rgba(35, 43, 85, 0.75), rgba(35, 43, 85, 0.95)),
    no-repeat center center;
  box-shadow: 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}
.userForm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}
.content {
  height: 382px;
  position: relative;
  overflow: auto;
}
.mastersFieldset {
  border: none;
}
.masterItem {
  display: flex;
  align-items: center;
}
