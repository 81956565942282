.adminPage {
  height: 100%;
  width: 100%;
  background: linear-gradient(rgba(35, 43, 85, 0.75), rgba(35, 43, 85, 0.95)),
    no-repeat center center;
  box-shadow: 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
}
.navBar {
  height: 70px;
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.adminItem {
  height: calc(100% - 70px);
  width: 100%;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}
.form {
  width: 560px;
}
.errorContainer {
  position: relative;
}
.list {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.listItem {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  border-bottom: 1px solid lightsalmon;
  position: relative;
  width: 100%;
}
.itemInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.buttons {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
