.navList {
  display: flex;
  list-style: none;
}
.link {
  margin: 0 10px;
  font-size: 13px;
  text-align: left;
  text-transform: uppercase;
  cursor: pointer;
  color: #ffffff;
  text-decoration: none;
}
