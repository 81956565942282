.showPasswordButton {
  background: transparent;
  border: none;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 35px;
  right: 10px;
  color: rgba(255, 160, 122);
}
.showPasswordButton:hover {
  scale: 1.1;
}
